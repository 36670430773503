import api from '@/app/api'

export default {
  async getOrders() {
    const response = await api.get('/order')
    return response
  },

  async createOrder(orderData) {
    const response = await api.post('/order', orderData)
    return response
  },

  async getOrderById(orderId) {
    const response = await api.get(`/order/${orderId}`)
    return response
  },

  async editOrder(orderId, orderData) {
    const response = await api.put(`/order/${orderId}`, orderData)
    return response
  },

  async deleteOrder(orderId) {
    const response = await api.delete(`/order/${orderId}`)
    return response
  },

  async setCourierInOrder(orderId, courier) {
    const response = await api.put(`/order/${orderId}/set-courier`, courier)
    return response
  },

  async setStatusInOrder(orderId, status) {
    const response = await api.put(`/order/${orderId}/set-status`, status)
    return response
  },

  async addCommentInOrder(orderId, comment) {
    const response = await api.post(`/order/${orderId}/add-comment`, comment)
    return response
  },
}
