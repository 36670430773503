<template>
  <v-col
    cols="12"
    md="12"
  >
    <base-material-card
      color="warning"
      class="px-5 py-3"
    >
      <template v-slot:heading>
        <div class="text-h3 font-weight-light">Заказы</div>

        <div class="text-subtitle-1 font-weight-light">
          <h5>Общее количество: {{ ordersList.length }}</h5>
        </div>
      </template>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="ordersList"
          :loading="loading"
        >
          <template v-slot:[`item.created_at`]="{ item }">
            <span>
              {{ dateFormatter(item.created_at) }}
            </span>

            <span>
              {{ dateFormatter(item.created_at, 'LTS') }}
            </span>
          </template>
        </v-data-table>
      </v-card-text>
    </base-material-card>
  </v-col>
</template>

<script>
import OrdersService from '@/app/Services/OrdersService'
import TableDialogMixin from '@/app/Helpers/mixins/BaseTableDialog'
import dateFormatter from '@/app/Helpers/dateFormatter'

export default {
  name: 'OrdersDashboard',

  mixins: [TableDialogMixin],

  data() {
    return {
      ordersList: [],
      headers: [
        {
          sortable: true,
          text: 'ID',
          value: 'id',
        },
        {
          sortable: false,
          text: 'Тип',
          value: 'delivery_type',
          align: 'left',
        },
        {
          sortable: true,
          text: 'Сумма заказа',
          value: 'price',
          align: 'left',
        },
        {
          sortable: false,
          text: 'Улица',
          value: 'delivery_street',
          align: 'left',
        },
        {
          sortable: true,
          text: 'Клиент ID',
          value: 'user_id',
          align: 'right',
        },
        {
          sortable: false,
          text: 'Клиент',
          value: 'customer_name',
          align: 'right',
        },
        {
          sortable: false,
          text: 'Номер телефона',
          value: 'phone',
          align: 'right',
        },
        {
          sortable: true,
          text: 'Дата',
          value: 'created_at',
        },
      ],
    }
  },

  computed: {},

  async created() {
    this.loading = true
    await this.getOrders()
    this.loading = false
  },

  methods: {
    dateFormatter,
    async getOrders() {
      const response = await OrdersService.getOrders()

      if (response.status) {
        this.ordersList = response.data?.orders

        this.$store.dispatch('orders/setOrdersData', response.data)
      }
    },
  },
}
</script>
